import { Flex, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React from 'react';
import SEO from '../../components/seo';

function FAQsPage({ data }) {
  const seoTitle = data.pageSeo.edges[0].node.pageName;
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  const faqs = data.faqs.nodes;
  return (
    <>
      <SEO title="FAQs" description={seoDescription} />
      <Flex flexDir="column" w="100%" mt={{ base: 8, lg: 16 }} mb={8}>
        <Heading as="h1" size="xl" mb={4}>
          Frequently Asked Questions
        </Heading>
        <Flex mt={5} flexDir="column">
          {faqs.map((faq) => (
            <Flex flexDir="column" mb={8}>
              <Text fontWeight="semibold">{faq.question.question}</Text>
              <Text>{faq.answer.answer}</Text>
            </Flex>
          ))}
        </Flex>
        <Text
          py={6}
          px={{ base: 6, md: 12 }}
          bgColor="primary.100"
          color="primary.500"
          fontWeight="semibold"
          borderRadius="2xl"
        >
          If you have any more questions, please do not hesitate to contact our
          friendly team on 1300 738 278 or for international orders on +61 2
          6680 8910. You can also email us at admin@parentshop.com.au.
        </Text>
      </Flex>
    </>
  );
}

export default FAQsPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(filter: { pageName: { eq: "faqs" } }) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    faqs: allContentfulFaq {
      nodes {
        question {
          question
        }
        answer {
          answer
        }
      }
    }
  }
`;
